

export default async function(file) {
  var outputTransactions = await readTransactions(file); // 0 -> totals, 1 -> retrocharges, 2 -> invoiced, 3 -> order type totals only.

  console.log('Transactions:', outputTransactions[0]['total']);

  return outputTransactions;
}

function findHeaderIndices(headers, defaultCols) {
	for (var header of Object.keys(defaultCols)) {
		if (headers.includes(header)) {
			defaultCols[header] = headers.indexOf(header);
		}
	}
	return defaultCols;
}

async function readTransactions(file) {

	/*
	var defaultCols = {
		'date': 0,
		'type': 2,
		'account type': 8,
		'product sales': 14,
		'shipping': 16,
		'gift wrap': 18,
		'promo': 22,
		'tax': 24
	}
	*/

	// The headers we are looking for. We will set the columns they are in according to where we actually find them in the CSV.
	// This allows the user to upload a CSV with customer sensitive data removed, and have it still work (city/zip/state).
	var defaultCols = {
		'date/time': 0,
		'type': 2,
		'account type': 8,
		'product sales': 14,
		'shipping credits': 16,
		'gift wrap credits': 18,
		'promotional rebates': 22,
		'marketplace withheld tax': 24
	}

	var totals = {
		'January': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'February': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'March': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'April': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'May': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'June': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'July': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'August': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'September': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'October': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'November': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'December': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'total': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0}
	}

	var totalsOrdersOnly = {
		'January': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'February': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'March': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'April': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'May': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'June': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'July': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'August': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'September': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'October': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'November': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'December': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0},
		'total': {'Product Sales': 0, 'Shipping Credits': 0, 'Gift Wrap Credits': 0, 'Promotional Rebates': 0, 'Sales Tax Collected': 0}
	}

	var retrochargeTxns = [];
	var invoicedOrders = [];

    try {

	    const data = await file.text();
		var rows = data.split('\n');

		var cols;

		var num = 0;
		for (const row of rows) {

			// TODO: Split on comma ONLY if the comma is not inside quotes. Does this work?
			//const record = row.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g);
			var record = row.split('","');
			// Remove leading/trailing double quotes from first and last entry.
			record[0] = record[0].replaceAll('"', '');
			record[record.length-1] = record[record.length-1].replaceAll('"', '');

			// Find header row. NOTE: depends on ONLY one cell in entire 1st column have 'date/time' text inside it.
			if (record[0].includes('date/time')) {
				cols = findHeaderIndices(record, defaultCols);
			}

			// Work with each record
			// NOTE: Row 7 is the header rows, so we only care about what's after that.
			if (num > 7) {
				if (!cols) {
					console.log('Transactions CSV is missing header row, or first header is not date/time.');
					return;
				}
				analyzeTransaction(cols, totals, record, retrochargeTxns, invoicedOrders, totalsOrdersOnly);
			}
			num++;
		}


		//////////////
		// ALL TXNS
		for (var month in totals) {
			if (month == 'total') {
				continue;
			}
			totals.total['Product Sales'] += totals[month]['Product Sales'];
			totals.total['Shipping Credits'] += totals[month]['Shipping Credits'];
			totals.total['Gift Wrap Credits'] += totals[month]['Gift Wrap Credits'];
			totals.total['Promotional Rebates'] += totals[month]['Promotional Rebates'];
			totals.total['Sales Tax Collected'] += totals[month]['Sales Tax Collected'];
		}

		// Round all totals to two decimal places.
		for (var sum in totals.total) {
			//totals.total[sum] = ( Math.round( totals.total[sum] ) / 100 ).toFixed(2);
			totals.total[sum] = round( totals.total[sum], 2 );
		}

		// ORDERS ONLY TXNS
		for (var month in totalsOrdersOnly) {
			if (month == 'total') {
				continue;
			}
			totalsOrdersOnly.total['Product Sales'] += totalsOrdersOnly[month]['Product Sales'];
			totalsOrdersOnly.total['Shipping Credits'] += totalsOrdersOnly[month]['Shipping Credits'];
			totalsOrdersOnly.total['Gift Wrap Credits'] += totalsOrdersOnly[month]['Gift Wrap Credits'];
			totalsOrdersOnly.total['Promotional Rebates'] += totalsOrdersOnly[month]['Promotional Rebates'];
			totalsOrdersOnly.total['Sales Tax Collected'] += totalsOrdersOnly[month]['Sales Tax Collected'];
		}

		// Round all totals to two decimal places.
		for (var sum in totalsOrdersOnly.total) {
			//totalsOrdersOnly.total[sum] = ( Math.round( totalsOrdersOnly.total[sum] ) / 100 ).toFixed(2);
			totalsOrdersOnly.total[sum] = round( totalsOrdersOnly.total[sum], 2 );
		}

		//console.log('Transactions:', totals.total);

		return [totals, retrochargeTxns, invoicedOrders, totalsOrdersOnly];

	} catch (e) {
		var msg = 'Error analyzing CSV file: ' + e.message;
		console.log(msg);
		alert(msg);
	}

}

function round(num, precision) {
	return Number(Math.round(num + "e+" + precision) + "e-" + precision);
}

function analyzeTransaction(cols, totals, txn, retroTxns, invOrds, totalsOrdersOnly) {
	var month = getTxnMonth(txn, cols['date/time']);
	if (month) {
		totals[month]['Product Sales'] += parseFloat( txn[cols['product sales']].replaceAll(',', '') );
		totals[month]['Shipping Credits'] += parseFloat( txn[cols['shipping credits']].replaceAll(',', '') );
		totals[month]['Gift Wrap Credits'] += parseFloat( txn[cols['gift wrap credits']].replaceAll(',', '') );
		totals[month]['Promotional Rebates'] += parseFloat( txn[cols['promotional rebates']].replaceAll(',', '') );
		totals[month]['Sales Tax Collected'] += parseFloat( txn[cols['marketplace withheld tax']].replaceAll(',', '') );

		if (txn[cols['type']].toLowerCase().includes('order_retrocharge')) {
			retroTxns.push(txn);
		}

		if (txn[cols['account type']].toLowerCase().includes('invoice')) {
			invOrds.push(txn);
		}

		if (txn[cols['type']].toLowerCase() == 'order') {
			totalsOrdersOnly[month]['Product Sales'] += parseFloat( txn[cols['product sales']].replaceAll(',', '') );
			totalsOrdersOnly[month]['Shipping Credits'] += parseFloat( txn[cols['shipping credits']].replaceAll(',', '') );
			totalsOrdersOnly[month]['Gift Wrap Credits'] += parseFloat( txn[cols['gift wrap credits']].replaceAll(',', '') );
			totalsOrdersOnly[month]['Promotional Rebates'] += parseFloat( txn[cols['promotional rebates']].replaceAll(',', '') );
			totalsOrdersOnly[month]['Sales Tax Collected'] += parseFloat( txn[cols['marketplace withheld tax']].replaceAll(',', '') );
		}
	}
}

function getTxnMonth(txn, col) {
	var date = txn[col].toLowerCase();

	if (date.includes('jan')) {
		return 'January';
	} else if (date.includes('feb')) {
		return 'February';
	} else if (date.includes('mar')) {
		return 'March';
	} else if (date.includes('apr')) {
		return 'April';
	} else if (date.includes('may')) {
		return 'May';
	} else if (date.includes('jun')) {
		return 'June';
	} else if (date.includes('jul')) {
		return 'July';
	} else if (date.includes('aug')) {
		return 'August';
	} else if (date.includes('sep')) {
		return 'September';
	} else if (date.includes('oct')) {
		return 'October';
	} else if (date.includes('nov')) {
		return 'November';
	} else if (date.includes('dec')) {
		return 'December';
	}

	return null;
}
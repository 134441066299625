//import logo from './logo.svg';
//import logo from './assets/logo.jpeg';
import './App.css';
import { useState, useRef } from 'react';
import analyzeCSV from './analyzeCSV.js';
import spinner from './assets/spinner.gif';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDgQ6XF7WIFqHHgvRP9ZR0AI7d4-_wSvUc",
  authDomain: "amz1099-52e7a.firebaseapp.com",
  projectId: "amz1099-52e7a",
  storageBucket: "amz1099-52e7a.appspot.com",
  messagingSenderId: "205827198969",
  appId: "1:205827198969:web:0af70dac62673647bbb728",
  measurementId: "G-XHZ7JV3DXS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


// TODO: Swap when live or testing:
//var funUrl = 'http://127.0.0.1:5001/amz1099-52e7a/us-central1/appES/'; // Local emulator
var funUrl = 'https://appes-dnk2mh3fta-uc.a.run.app/'; // Live

function App() {

  const [ten99, set1099] = useState('');
  const [summary, setSummary] = useState('');
  const [txnCSV, setTxnCSV] = useState('');
  const [error, setError] = useState(null);
  const [reportType, setReportType] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [remove, setRemove] = useState('');
  const [showAbout, setShowAbout] = useState(false);
  const [accessCode, setAccessCode] = useState('trial');
  const [email, setEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  console.log('Files:');
  console.log(ten99.name);
  console.log(summary.name);
  console.log(txnCSV.name);
  console.log(reportType);

  var props = {
    reportType: reportType,
    setReportType: setReportType,
    ten99: ten99,
    set1099: set1099,
    summary: summary,
    setSummary: setSummary,
    txnCSV: txnCSV,
    setTxnCSV: setTxnCSV,
    remove: remove,
    setRemove: setRemove,
    showAbout: showAbout,
    setShowAbout: setShowAbout,
    accessCode: accessCode,
    setAccessCode: setAccessCode,
    email: email,
    setEmail: setEmail,
    isLoading: isLoading,
    setIsLoading: setIsLoading
  }

  console.log('Props:', props);

  // 1) Run analysis on CSV, consolidate data.
  // 2) Send the CSV considatated data and other two files to server for processing.

  const submitForm = async () => {

    // TODO: only require 1099 when doing an annual report. Optional for monthly report.
    if (!ten99 && reportType == 'annual') {
      alert('A 1099 is required.');
      return;
    }

    if (!summary && !txnCSV) {
      alert('A Summary or Transactions CSV is required.');
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    if (email) formData.append('email', email);
    if (reportType) formData.append('type', reportType);
    if (ten99) formData.append('form1099', ten99, ten99.name);
    if (summary) formData.append('formSummary', summary, summary.name);
    if (txnCSV) {
      // Analyze CSV on browser.
      var dataCSV = await analyzeCSV(txnCSV);

      // Send dataCSV to server with other files.
      formData.append('formCSV', JSON.stringify(dataCSV));

    }

    try {
      const response = await fetch(funUrl + 'submitForm', {
        method: "POST",
        body: formData,
      });
      var jsonRes = await response.json();
      console.log('Response:', jsonRes);

      setDownloadUrl(jsonRes.downloadUrl);

      window.open(jsonRes.downloadUrl, '_blank');

      setIsLoading(false);

    } catch (e) {
      console.log('Error submitting form: ', e.message);
      setIsLoading(false);
      alert('Error: ' + e.message);
    }

  };

  const submitCode = async (code, email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( !re.test(email) ) {
      alert('Invalid email address.');
      return;
    }

    setIsLoading(true);

    var body = {
      email: email,
      accessCode: code
    }
    try {
      const response = await fetch(funUrl + 'submitCode', {
        method: "POST",
        body: JSON.stringify(body),
      });
      var jsonRes = await response.json();
      console.log('Response:', jsonRes);

      if (jsonRes.message) throw new Error(jsonRes.message);

      setAccessCode(code);
      setEmail(email);

      setIsLoading(false);

    } catch (e) {
      setIsLoading(false);
      console.log('Error submitting code: ', e.message);
      alert(e.message);
    }

    // Google conversion tracking
    window.gtag('config', 'AW-302854923');
    window.gtag('event', 'conversion', {'send_to': 'AW-302854923/sIcqCLLy04EZEIvmtJAB'});

  }

  // On file select (from the pop up)
  function on1099FileChange(event) {
      console.log('on1099FileChange called.');

      //readSummary()

      // Update the state
      set1099(event.target.files[0]);
  };

  function onSummaryFileChange(event) {
      // Update the state
      setSummary(event.target.files[0]);
  };

  function onTxnFileChange(event) {
      // Update the state
      setTxnCSV(event.target.files[0]);
  };

  const backClicked = async () => {
    setReportType(null);
  }

  return (
    <div className="App">
      <Loading props={props} />
      <header className="App-header">
        <div>Amazon 1099</div>
        <div>Automated Reconciler</div>
        <div>(BETA)</div>
        <br/>
        <div style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setShowAbout(true)}>About</div>

        <div id="myModal" className="modal" style={{display: showAbout ? 'block' : 'none'}}>
          <div className="modal-content">
            <div className="modal-header">
              <span className="close" onClick={() => setShowAbout(false)}>&times;</span>
              <span>Annual and Monthly Report Types</span>
            </div>
            <div className="modal-body">
              Generate your Summary and Transaction reports from here: <a target="_blank" href="https://sellercentral.amazon.com/payments/reports-repository">https://sellercentral.amazon.com/payments/reports-repository</a>
              <br/>
              Get your 1099 here: <a target="_blank" href="https://sellercentral.amazon.com/gp/tax/tax-library.html">https://sellercentral.amazon.com/gp/tax/tax-library.html</a>
              <br/><br/>
              Using the "Annual" option, this tool will generate a PDF report comparing the values in each category of your 1099 with the values in the same categories on your Summary and/or Transaction report.
              <br/><br/>
              It will tell you why the numbers may not match, and list the transactions that typically cause discrepencies. Orders paid by invoice, and "retrocharge" transactions are some examples of the types of transactions that cause discrepencies.
              <br/><br/>
              Using the "Monthly" option, this tool will generate a PDF report comparing the values on your Summary report with those on your Transaction report. It will give an explanation just like the annual report, but without your 1099 values.
            </div>
          </div>
        </div>

      </header>

      <div className="tileRow">
        <div className="fullTile">
          <div className="tile_header_div">{!email ? 'Enter Email' : (!reportType ? 'Choose a Report Type' : 'Select Files')}</div>
          <div className="tile_content_div">

            <AccessCode props={props} submitCode={submitCode}/>

            <div className='reportSelect' style={{display: !email ? 'none' : (!reportType ? 'block' : 'none')}}>
              <span><button onClick={() => setReportType('monthly')}>Monthly</button></span>
              <span><button onClick={() => setReportType('annual')}>Annual</button></span>
            </div>

            <div className="uploadBundle" style={{display: reportType ? 'block' : 'none'}}>
              <Upload1099 onFileChange={on1099FileChange} props={props}/>
              <UploadSummary onFileChange={onSummaryFileChange} props={props} />
              <UploadTransactions onFileChange={onTxnFileChange} props={props} />

              <div style={{display: downloadUrl ? "block" : "none"}}>
                <p style={{textAlign: 'center'}}><a target="_blank" href={downloadUrl}>View PDF</a></p>
              </div>

              <div style={{textAlign: 'center'}}>
                <span><button onClick={backClicked}>Back</button></span>
                <span><button onClick={submitForm}>Submit</button></span>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div>Need help? Report an issue? <a target="_blank" href="https://bluewarelabs.com/contact">Contact us.</a></div>
    </div>
  );
}

function removeFile(type, props, inputFile) {
    switch (type) {
      case 'ten99':
        props.set1099('');
        break;
      case 'summary':
        props.setSummary('');
        break;
      default:
        props.setTxnCSV('');
    }
    inputFile.current.value = '';
  }

// TODO: Test this: Implement a remove file for each using .value = '' and setState to null for that file.
function Upload1099({onFileChange, props}) {
  // Ref object to reference the input element
  const inputFile = useRef(null);

  return (
    <div style={{display: props.reportType == 'annual' ? 'block' : 'none'}}>
      <label className="uploadLabel" htmlFor="upload1099">Upload 1099 (required): </label>
      <span className="rmFileIcon" style={{display: props.ten99 ? 'inline' : 'none'}} onClick={() => removeFile('ten99', props, inputFile)}>X</span>
      <input type="file" id="upload1099" name="upload1099" onChange={onFileChange} ref={inputFile} />
      <br/>
      <br/>
      <div style={{textAlign:'center', clear: 'both'}}>At least one is is required:</div>
    </div>
  );
}

function UploadSummary({onFileChange, props}) {
  // Ref object to reference the input element
  const inputFile = useRef(null);

  return (
    <div>
      <label className="uploadLabel" htmlFor="uploadSummary">Upload Summary Report: </label>
      <span className="rmFileIcon" style={{display: props.summary ? 'inline' : 'none'}} onClick={() => removeFile('summary', props, inputFile)}>X</span>
      <input type="file" id="uploadSummary" name="uploadSummary" onChange={onFileChange} ref={inputFile} />
    </div>
  );
}

function UploadTransactions({onFileChange, props}) {
  // Ref object to reference the input element
  const inputFile = useRef(null);

  return (
    <div>
      <label className="uploadLabel" htmlFor="uploadTransactions">Upload Transactions CSV: </label>
      <span className="rmFileIcon" style={{display: props.txnCSV ? 'inline' : 'none'}} onClick={() => removeFile('txnCSV', props, inputFile)}>X</span>
      <input type="file" id="uploadTransactions" name="uploadTransactions" onChange={onFileChange} ref={inputFile} />
    </div>
  );
}

function AccessCode({props, submitCode}) {
  const inputRef = useRef(null);
  const emailRef = useRef(null);
  return (
    <div className="access-code" style={{display: !props.email ? 'block' : 'none'}}>
      <div>Your email can be used for future access to your report.</div>
      <br/><br/>
      <label className="uploadLabel" htmlFor="Email">Email: </label>
      <input type="email" id="Email" name="Email" ref={emailRef} /*onChange={e => props.setEmail(e.target.value)}*/ />
      <br/><br/>
      <label style={{display: 'none'}} className="uploadLabel" htmlFor="accessCode">Access Code: </label>
      <input style={{display: 'none'}}  type="text" id="accessCode" name="accessCode" ref={inputRef} />
      <br/><br/>
      <button onClick={() => submitCode(props.accessCode, emailRef.current.value)}>Submit</button>
    </div>
  );
}

function Loading({props}) {
  return (
    <div className="popup" style={{display: props.isLoading ? 'flex' : 'none'}} onClick={e => e.stopPropagation()}>
      <img src={spinner} style={{width: '100px'}} />
    </div>
  )
}

export default App;
